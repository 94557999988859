<template>
   <div class="record-page">
       <div class="record-header" >
            <div :class="menuIndex == index ? 'record-header-text' : 'record-show' " @click="menuShow(index)" v-for="(item,index) in navList" :key='index'  >
                <span class='record-ative'>{{item}}</span>
            </div>
        </div>
        <!-- 有数据 -->
        <div v-show="loanList!=null">
            <div class='record-main-card' v-if='loanList!=null'>
                <!-- 待完成 待提现2  授信中1 放款中4 -->
                <div v-show='menuIndex==0' class="record-card"  v-for='item in loanList' :key='item.appNo'>
                    <div class="record-card-left">
                        <div class="record-card-left-header">
                            <img class="card-img" :src='item.appLogo' alt="产品logo"/>
                            <div class="card-text">{{item.appName}}</div>
                        </div>
                        <div class="record-card-des">
                            <div >
                                <span  class="record-card-title">待提现额度：</span>
                                <span :class="item.status==1?'record-card-color' :'record-card-num'" >￥{{item.amount}}</span>
                            </div>
                            
                        </div>
                    </div>
                    <div class='record-card-right'>
                        <div class="record-card-r-btn" v-if="item.status==2"  @click="goback(item.url,item)">
                            <div class="record-card-btn">{{item.statusStr}}</div>
                        </div>
                        <div class="record-card-s-btn" v-else>
                            <div class="record-card-btns">{{item.statusStr}}</div>
                        </div>
                    </div>
                
                </div>
                <!-- 待还款：去还款6 逾期7（去还款） -->
                <div v-show='menuIndex==1' v-if='loanList!=null'>
                    <div  class='record-bill' v-for='item in loanList' :key='item.appNo'>
                        <div class='record-bill-main' >
                            <div class='bill-header'>
                                <div class='bill-header-name'>
                                    <img  class="bill-card-img" :src="item.appLogo" alt='产品图'/>
                                    <div class="bill-card-text">{{item.appName}}</div>
                                </div>
                                <div class='bill-header-right' v-if='item.orderStatus=="CURRENT"'>
                                    <span class='bill-header-texy'>待还款</span> 
                                </div>
                                <div class='bill-header-right' v-if='item.orderStatus=="OVERDUED" '>
                                    <span class='bill-header-texy bill-color'>已逾期</span> 
                                </div>
                                <div class='bill-header-right' v-if='item.orderStatus!="CURRENT" && item.orderStatus!="OVERDUED"'>
                                    <span class='bill-header-texy'>未到期</span> 
                                </div>
                            </div>
                            <div class='bill-center'> 
                                <div class="bill-center-left">
                                    <span class='left-text'>本期账单：</span>
                                    <span class="left-number">￥{{item.currentAmt}}</span>
                                </div>
                                <div class="bill-center-right">{{item.currentDate}}</div>
                            </div>
                            <div class='bill-btn'  @click="goback(item.url,item)">{{item.statusStr}}</div>
                        </div>
                    </div>
                </div>
            
                <!-- 全部：审批中1 去提现2 未通过3 放款中4 放款失败5 去还款6 预期7 已结清8 放款取消9 -->
                <div v-show='menuIndex==2' v-if='loanList!=null'>
                    <div  class='record-all' v-for='item in loanList' :key='item.appNo'>
                        <div class="record-card-left">
                            <div class="record-card-left-header">
                                <img class="card-img" :src='item.appLogo' alt="产品logo"/>
                                <div class="card-text">{{item.appName}}</div>  
                            </div>
                            <div class="record-card-des">
                                <div v-if ='item.status==6 || item.status==7'>
                                    <span  class="record-card-title">本期账单：</span>
                                    <span class='record-card-num'>￥{{item.currentAmt}}</span>
                                </div>
                                <div v-else>
                                    <div v-if='item.status==1 || item.status==2 || item.status==4'>
                                        <span  class="record-card-title">待提现额度：</span>
                                        <span  :class="item.status==1?'record-card-color' :'record-card-num'">￥{{item.amount}}</span>
                                    </div>
                                    <div v-else>{{item.amount}}</div>
                                </div> 
                            </div>
                        </div>

                        <div class='record-card-right'>
                            <div v-if="item.status==6 || item.status==7">
                                    <div class='record-card-r-t' v-if='item.orderStatus=="CURRENT"'>
                                        待还款
                                    </div>
                                    <div class='record-card-r-t record-card-r-color' v-if='item.orderStatus=="OVERDUED"'>
                                        已逾期
                                    </div>
                                    <div class='record-card-r-t' v-if='item.orderStatus!="CURRENT" && item.orderStatus!="OVERDUED"'>
                                        未到期
                                    </div>
                            </div>
                            <div class="record-card-date">{{item.currentDate}}</div> 
                            <div class='record-card-b'>
                                <div class="record-card-r-btn" v-if='item.status==2 || item.status==6 || item.status==7' @click="goback(item.url,item)">
                                    <div class="record-card-btn">{{item.statusStr}}</div>
                                </div>
                                <div class="record-card-s-btn" v-else>
                                    <div class="record-card-btns">{{item.statusStr}}</div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>         
            </div>
        </div>
        <!-- 暂无借款数据 -->
        <div v-show='menuIndex==0 || menuIndex==1 || menuIndex==2'>
            <div class='record-null' v-show="loanList==''">
                <div class='record-null-img'></div> 
                <!-- <template> -->
                    <div class='record-null-text' v-if='menuIndex==1'>目前还没有待还款订单哦</div>
                    <div class='record-null-text' v-else>目前还没有借款记录哦</div>  
                <!-- </template> -->
             
               
            </div>
        </div>
        
       
   </div> 
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'


export default ({
    setup() {
        const state=reactive({
           menuIndex:0,
           navList:['待完成订单','待还款订单','全部'],
           loanList:[],

        })
        const menuShow=(index)=>{
            state.menuIndex=index
            initRecord()
            console.log(state.menuIndex);
        }
        const initRecord=async ()=>{
          //  WAIT_LOAN 待完成订单、 WAIT_REPAY 待还款订单 、ALL 全部订单
          let type;
            if(state.menuIndex==0){
                type='WAIT_LOAN'
            }else if(state.menuIndex==1){
                type='WAIT_REPAY'
            }else{
                 type='ALL'
            }
            const result= await API.loanRecord({
                type
            })
    
            state.loanList=result.loanList
           
        }
        const goback=(item,items)=>{
            window.localStorage.setItem('appNo',items.appNo)
            window.localStorage.setItem('loanNo',items.loanNo)   
            APP.JUMP_H5(item)
           
            
        }
        onMounted(() => {
            window.TDAPP.onEvent("借款记录页-进入");
            APP.SET_TITLE('借款记录')
            initRecord()
        })
        return{
            ...toRefs(state),
            menuShow,
            goback
        }
    },
})
</script>